import React, { Component } from "react";
import ModuleButton from "./modulesButton.component";
import { ModuleService } from "../../service/ModuleService";
import ModulesHeaderComponent from "./modulesHeader.component";
import { getAllModules } from "../../service/ModuleService";
import "./modules.styles.css";
import "./modulesButton.styles.css";
import "../../pages/dashboard/mainPage.styles.css";
import PermissionDenied from "./permissionDenied.component";
import SeverError from "./serverError.component";
export default class Modules extends Component {
  constructor(props) {
    super();
    this.state = {
      modules: [],
      loading: true,
      errorMessage: null,
    };
  }

  componentDidMount() {
    getAllModules()
      .then((data) => {
        this.setState({ modules: data, loading: false });
      })
      .catch((error) => {
        this.setState({
          modules: null,
          errorMessage: error.message,
          loading: false,
        });
      });

    // const moduleService = new ModuleService()
    // moduleService.getModules().then((data) => this.setState({ modules: data }))
  }

  render() {
    const { loading, modules } = this.state;
    let content;
    if (loading) {
      content = null;
    } else if (!Array.isArray(modules)) {
      // Loaded but not an array, server error
      content = <SeverError errorMessage={this.state.errorMessage} />;
    } else if (modules.length > 0) {
      // Loaded and have modules.
      content = (
        <section className="moduleButtonList">
          {modules.map((module) => (
            <ModuleButton
              key={module.app_name}
              name={module.app_name}
              icon={module.app_icon}
              header={module.app_name}
              body={module.app_description}
              background={module.app_background}
              href={module.app_url}
              disabled={module.disabled}
            />
          ))}
        </section>
      );
    } else {
      // Loaded but no modules, hence permission denied.
      content = <PermissionDenied />;
    }
    return (
      <>
        <ModulesHeaderComponent />
        {content}
      </>
    );
  }
}
